import React, {useState} from 'react';


import Radium from 'radium';
import VrstaObjave from "../components/VrstaObjave";

const Objave = (props) => {

	const [button, ] = useState(false)
	const [titleState, ] = useState("Objave")
	const [prispevek, setPrispevek] = useState(false)
	const [dogotek, setDogotek] = useState(false)
	const [spreglejte, setSpreglejte] = useState(false)
	const [novica, setNovica] = useState(false)
	const [, setKey] = useState("")
	const [objava, ] = useState([...props.fields].reverse())
	const [objavaSearched, setObjavaSearched] = useState([...props.fields].reverse())
	const [objavaFilterd, setObjavaFilterd] = useState([...props.fields].reverse())


	function handleSearch(e){

		setKey(e.nativeEvent.target.value)
		var key = e.nativeEvent.target.value;


		if(e.nativeEvent.target.value.length>2){
			var neki = search(key);

			if(objavaFilterd.length <= 0){
				document.getElementById("errMassage").innerHTML = "NI ZADETKA";
				document.getElementById("errMassage").style.color = "#cc0000";
			} else {

				var one;
				if(objavaFilterd.length===1){
					one = "zadetek";
				} else if(objavaFilterd.length===2){
					one = "zadetka";
				} else if(objavaFilterd.length===3 || objavaFilterd.length===4){
					one = "zadetki";
				} else {
					one = "zadetkov";
				}
				document.getElementById("errMassage").innerHTML = `${objavaSearched.length} ${one} v ${neki.time} sekundah`;
				document.getElementById("errMassage").style.color = "#000";

			}
		} else {
			document.getElementById("errMassage").innerHTML = "";
			document.getElementById("errMassage").style.color = "#cc0000";
		}
		if(e.nativeEvent.target.value.length<1){
			deleteFilter();
			setObjavaSearched([...objava])
			setObjavaFilterd([...objava])
		}
		filter();

	}


	function search(e){
		let arr = [];
		var startTime = window.performance.now();

		var path = new RegExp(e,"i");
		objava.forEach((objava, i) => {

			var jeZe = true;
			if (objava.avtorObjave.match(path) && jeZe){
				arr.push(objava);
				jeZe = false;
			} else if(objava.body.match(path) && jeZe){
				arr.push(objava);
				jeZe = false;
			}else if(objava.date.match(path) && jeZe){
				arr.push(objava);
				jeZe = false;
			}else if(objava.kategorija.match(path) && jeZe){
				arr.push(objava);
				jeZe = false;
			}else if(objava.name.match(path) && jeZe){
				arr.push(objava);
				jeZe = false;
			}else if(objava.title.match(path) && jeZe){
				arr.push(objava);
				jeZe = false;
			}

			else if(jeZe && objava.minus){
				objava.minus.forEach((minus, f) => {
					if(minus.word){
						if(minus.word.match(path) && jeZe){
							arr.push(objava);
							jeZe = false;
						}
					}
				})
			}
			else if(jeZe && objava.plus){
				objava.plus.forEach((plus, m) => {
					if(plus.word){
						if(plus.word.match(path) && jeZe){
							arr.push(objava);
							jeZe = false;
						}
					}
				})
			}else if(jeZe && objava.keyWords){
				objava.keyWords.forEach((keyWords, m) => {
					if(keyWords.word){
						if(keyWords.word.match(path) && jeZe){
							arr.push(objava);
							jeZe = false;
						}
					}
				})
			}

		});

		var arr2 = [...arr.reverse()];
		setObjavaSearched([...arr2])
		var objekt = {};
		objekt.ali = (arr2.length > 0);
		objekt.time = ((window.performance.now() - startTime)/1000).toFixed(3);
		return objekt;
	}



	function handleClick1(){
		setPrispevek(!prispevek)
		setTimeout(() => {
			filter();
		},200);
	}
	function handleClick2(){
		setNovica(!novica)
		setTimeout(() => {
			filter();
		},200);
	}
	function handleClick3(){
		setSpreglejte(!spreglejte)
		setTimeout(() => {
			filter();
		},200);

	}
	function handleClick4(){
		setDogotek(!dogotek)
		setTimeout(() => {
			filter();
		},200);
	}

	function deleteFilter(){
		setPrispevek(false)
		setNovica(false)
		setSpreglejte(false)
		setDogotek(false)
		setTimeout(() => {
			filter();
		},200);
	}

	function filter(){
		var arr = [];
		if(prispevek){
			for(var i=0; i<objavaSearched.length; i++){
				if(objavaSearched[i].kategorija === "Prispevek"){
					arr.push(objavaSearched[i]);
				}
			}
		}
		if(novica){
			for(i=0; i<objavaSearched.length; i++){
				if(objavaSearched[i].kategorija === "Novica"){
					arr.push(objavaSearched[i]);
				}
			}
		}
		if(spreglejte){
			for(i=0; i<objavaSearched.length; i++){
				if(objavaSearched[i].kategorija === "Ne spreglejte"){
					arr.push(objavaSearched[i]);
				}
			}
		}
		if(dogotek){
			for(i=0; i<objavaSearched.length; i++){
				if(objavaSearched[i].kategorija === "Dogodek"){
					arr.push(objavaSearched[i]);
				}
			}
		}
		if(!prispevek && !novica && !spreglejte && !dogotek){
			setObjavaFilterd(objavaSearched)
		} else {
			setObjavaFilterd(arr)
		}
	}

	var arr = [...props.fields];
	arr.reverse();

	return(
		<div style={styles.wrapper}>

			<div style={styles.container}>


				<div style={styles.searchNews}>
					<div style={styles.searchNews_form}>
						Iskanje po objavah
						<div style={styles.searchNews_list}>
							<div style={styles.contacts_form_inputWraper_design}>
								<input
									onKeyUp={handleSearch}
									style={styles.contacts_form_input}
									type="text"
									placeholder="vpiši iskalni niz" />
							</div>
							<button style={styles.searchNews_gumb}>IŠČI</button>
						</div>
						<div id="errMassage" style={{fontWeight:500,marginTop:35,fontSize:16}}></div>
					</div>

				</div>

				<div style={styles.content}>


					<div style={styles.icons}>
						<div style={styles.iconsWrapper}>

							<div style={styles.iconsWrapper_responsive}>
								<div style={prispevek ? styles.iconsWrapper_boxOn : styles.iconsWrapper_boxOff} onClick={handleClick1}>
									<div style={styles.iconsWrapper_box_img_wrapper}>
										<img style={styles.iconsWrapper_box_img} src={prispevek ? "../assets/icons/prispevekWhite.png" : "../assets/icons/prispevekColor.png"} alt="icon_prispevek" />
									</div>
									<div style={prispevek ? styles.iconsWrapper_box_textOn : styles.iconsWrapper_box_textOff}>PRISPEVKI</div>
								</div>

							</div>


							<div style={styles.iconsWrapper_responsive}>
								<div onClick={handleClick2} style={novica ? styles.iconsWrapper_boxOn : styles.iconsWrapper_boxOff}>
									<div style={styles.iconsWrapper_box_img_wrapper}>
										<img style={styles.iconsWrapper_box_img} src={novica ? "../assets/icons/novicaWhite.png" : "../assets/icons/novicaColor.png"} alt="icon_novice" />
									</div>
									<div style={novica ? styles.iconsWrapper_box_textOn : styles.iconsWrapper_box_textOff}>NOVICE</div>
								</div>
							</div>


							<div style={styles.iconsWrapper_responsive}>
								<div onClick={handleClick3} style={spreglejte ? styles.iconsWrapper_boxOn : styles.iconsWrapper_boxOff}>
									<div style={styles.iconsWrapper_box_img_wrapper}>
										<img style={styles.iconsWrapper_box_img} src={spreglejte ? "../assets/icons/spreglejteWhite.png" : "../assets/icons/spreglejteColor.png"} alt="icon_neSpreglejte" />
									</div>
									<div style={spreglejte ? styles.iconsWrapper_box_textOn : styles.iconsWrapper_box_textOff}>NE SPREGLEJTE</div>
								</div>

							</div>


							<div style={styles.iconsWrapper_responsive}>
								<div onClick={handleClick4} style={dogotek ? [styles.iconsWrapper_boxOn, styles.iconsWrapper_box_last] : [styles.iconsWrapper_boxOff, styles.iconsWrapper_box_last]}>
									<div style={styles.iconsWrapper_box_img_wrapper}>
										<img style={styles.iconsWrapper_box_img} src={dogotek ? "../assets/icons/dogodekWhite.png" : "../assets/icons/dogodekColor.png"} alt="icon_dogodek" />
									</div>
									<div style={dogotek ? styles.iconsWrapper_box_textOn : styles.iconsWrapper_box_textOff}>DOGODKI</div>
								</div>
							</div>

						</div>

						{prispevek || novica || spreglejte || dogotek ?
							<div onClick={deleteFilter} style={styles.deleteFilter}>ODSTRANI VSE FILTRE <img alt="close" style={{width:20,height:20,marginLeft:10}} src="../assets/icons/close.png" /></div>
							:
							null
						}

					</div>




					<div style={styles.selectionWrapper}>
						<div style={styles.selectionWrapper_archive}>
							{titleState} ({objavaFilterd.length})
						</div>
					</div>

					<div style={styles.wrapper}>


						{objavaFilterd[0] !== undefined && objavaFilterd[0].nam !== undefined ?
							<div>{arr.map((objavaFilterd, i) => <VrstaObjave {...objavaFilterd} id={arr.length-i-1} key={i} />)}</div>
							:
							<div>{objavaFilterd.map((objavaFilterd, i) => <VrstaObjave {...objavaFilterd} key={i} />)}</div>
						}

						{button ?
							<div style={styles.content_gumb_positon}>
								<button style={styles.content_gumb}>POKAŽI VEČ</button>
							</div>
							:
							null
						}
					</div>


				</div>

			</div>
		</div>

	)
}


const styles = {
	wrapper:{
		position:"relative",
		backgroundColor:"#fff",
		width: "100%",
		marginBottom:50,
		
	},
	content:{
		position: "relative",
		maxWidth: 950,
		marginTop: "0px",
		marginBottom: "0px",
		marginRight: "auto",
		marginLeft: "auto",
		'@media (max-width: 1000px)':{
			marginLeft: 30,
			marginRight: 30
		},
		'@media (max-width: 370px)':{
			marginLeft: 10,
			marginRight: 10
		},
	},
	searchNews:{
		width: "100%",
		height: "35vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginBottom: 80,
		backgroundImage: "url(../assets/pictures/Group.png)",
		backgroundSize: "cover",
		'@media (max-width: 1200px)': {
			height: "25vh",
			paddingBottom: 30
		},
		'@media (max-width: 800px)': {
			paddingBottom: 0
		},
		'@media (max-width: 500px)': {
			marginBottom: 50,
		},
		'@media (max-width: 320px)': {
			height: "30vh"
		},

	},
	searchNews_form:{
		width: "100%",
		textAlign:"center",
		position: "relative",
		fontSize: 41,
		fontWeight: 300,
		color: "#243353",
		diplay: "block",
		'@media (max-width: 1150px)': {
			fontSize: 35,
			},
			'@media (max-width: 820px)': {
			fontSize: 30,
			},
			'@media (max-width: 700px)': {
			fontSize: 25,
			top: 0
			},
			'@media (max-width: 320px)': {
			fontSize: 25,
			top: "-5%"
			},
	},
	searchNews_list:{
		diplay: "flex",
		justifyContent: "center",
		position: "relative",
		top: 30,
		'@media (max-width: 1000px)': {
			top: 20
			},
			'@media (max-width: 700px)': {
			top: 10
			},
	},
	contacts_form_inputWraper_design:{
		borderRadius: 4,
		width: "28%",
		padding: "0px 0px 10px 15px",
		display: "inline-block",
		position: "relative",
		left: -13,
		backgroundColor: "#FFF",
		'@media (max-width: 1150px)': {
			padding: "5px 0px 5px 15px",
			},
			'@media (max-width: 1000px)': {
			padding: "5px 0px 5px 15px"
			},
			'@media (max-width: 820px)': {
			width: "32%"
			},
			'@media (max-width: 700px)': {
			display: "block",
			marginTop: "0px",
			marginRight: "auto",
			marginLeft: "auto",
			left: 0,
			marginBottom: 10,
			width: "40%"
			},
			'@media (max-width: 600px)': {
			width: "50%"
			},
	},
	contacts_form_input:{
		fontSize: 19,
		fontWeight: "300",
		borderTop: "none",
		borderRight: "none",
		borderBottom: "none",
		borderRadius:0,
		borderLeft: "2px solid #F5A623",
		padding: "10px 0px 10px 15px",
		width: "95%",
		outline: "none",
		'@media (max-width: 1000px)': {
			padding: "5px 0px 5px 15px",
			fontSize: 17,
			},	
			'@media (max-width: 820px)': {
			padding: "3px 0px 3px 10px",
			fontSize: 15,
			},
	},
	searchNews_gumb:{
		marginLeft: 10,
		backgroundColor: "#F89C2E",
		border: "none",
		padding: "10px 75px",
		color: "#fff",
		borderRadius: 4,
		fontSize: 15.84,
		letterSpacing: 2,
		fontWeight: 300,
		transition: "all .2s",
		cursor:"pointer",
		'@media (max-width: 1000px)': {
			padding: "10px 65px",
			fontSize: 15
		},
		'@media (max-width: 900px)': {
			padding: "5px 35px",
			fontSize: 13
		},
		'@media (max-width: 700px)': {
		
			marginTop: "0px",
			marginBottom: "0px",
			marginRight: "auto",
			marginLeft: 0,
		},
		":hover":{
			boxShadow: "0px 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
			transform: "translateY(-2px) scaleX(1.02) scaleY(1.02)",
		},

		":active":{
			outline: "none",
			transform: "translateY(-1px)",

		},
		":focus":{
			outline: "none",
			

		},

	},






	//icons
	deleteFilter:{
		cursor:"pointer",
		display:"flex",
		alignItems:"center",
		justifyContent:"flex-end",
	},

	iconsWrapper:{
		display: "flex",
		justifyContent: "flex-end",
		marginBottom: 56,
		'@media (max-width: 800px)':{
			justifyContent: "center"
		},
		'@media (max-width: 600px)':{
			flexFlow: "row wrap",
		},
		'@media (max-width: 500px)': {
			marginBottom: 20
		},
	},
	iconsWrapper_responsive:{
		cursor:"pointer",
		'@media (max-width: 600px)':{
			width: "35%",
			textAlign: "center",
			display: "flex",
			justifyContent: "center"
		},
		'@media (max-width: 480px)':{
			width: "50%"
		}
	},
	iconsWrapper_boxOff:{
		backgroundColor:"#fff",
		display: "inline-block",
		border: "1.5px solid #F5A623",
		borderRadius: 4,
		width: 115,
		height: 115,

		marginRight: 30,
		'@media (max-width: 600px)':{
			marginRight: 0,
			marginBottom: 30,
		},
		'@media (max-width: 400px)': {
			width: 95,
			height: 95
		},
		
	},
	iconsWrapper_boxOn:{
		backgroundColor:"#F5A623",
		display: "inline-block",
		border: "1.5px solid #F5A623",
		borderRadius: 4,
		width: 115,
		height: 115,

		marginRight: 30,
		'@media (max-width: 600px)':{
			marginRight: 0,
			marginBottom: 30,
		},
		'@media (max-width: 400px)': {
			width: 95,
			height: 95
		},
		
	},
	iconsWrapper_box_last:{
		marginRight: 0,
	},
	iconsWrapper_box_textOn:{
		color: "#fff",
		fontSize: 12,
		fontWeight: 400,
		paddingTop: 20,
		textAlign: "center",
		'@media (max-width: 400px)': {
			paddingTop: 10,
		},
	},
	iconsWrapper_box_textOff:{
		color: "#203356",
		fontSize: 12,
		fontWeight: 400,
		paddingTop: 20,
		textAlign: "center",
		'@media (max-width: 400px)': {
			paddingTop: 10,
		},
	},
	iconsWrapper_box_exception:{
		paddingTop: 15,
		'@media (max-width: 400px)': {
			paddingTop: 5,
		},
	},
	iconsWrapper_box_img_wrapper:{
		textAlign: "center",
		margin: "0px auto",
		paddingTop: 25,
		'@media (max-width: 400px)': {
			paddingTop: 15,
		},
	},
	iconsWrapper_box_img:{
		height:40,
	},


	//icons end



	selectionWrapper:{
		display: "flex",
		flexFlow: "row wrap",
		justifyContent: "space-between",
		alignItems: "baseline",
		marginBottom: 40,
		'@media (max-width: 384px)': {
			marginBottom: 20,
		},
	},
	selectionWrapper_archive:{
		display: "inline-block",
		fontSize: 40,
		fontWeight: 300,
		color: "#243353",
		marginRight: 40,
		'@media (max-width: 985px)': {
			marginBottom: 10
		},
		'@media (max-width: 380px)': {
			fontSize: 35,
			marginRight: 0
		},
		'@media (max-width: 300px)': {
			fontSize: 30,
			marginRight: 0
		},
	},
	selectionWrapper_alphabet:{
		display: "inline-block",
	},
	selectionWrapper_alphabet_word:{
		display: "inline-block",
		fontSize: 19,
		fontWeight: 300,
		color: "#243353",
		marginRight: 15,
		cursor: "pointer",
		":hover":{
			transition: "all 0.2s",
			transform: "scale(1.3)",
			color: "#F89C2E",
		},
		'@media (max-width: 679px)': {
			marginRight: 10
		},
		'@media (max-width: 559px)': {
			marginRight: 5,
			fontSize: 15
		},
		'@media (max-width: 384px)': {
			marginRight: 5,
			fontSize: 12
		},
	},
	selectionWrapper_alphabet_word_last:{
		marginRight: 0,
		'@media (max-width: 679px)': {
			marginRight: 0,
		},
	},
	content_gumb_positon:{
		display: "flex",
		justifyContent: "center",
		marginTop: 10,
		marginBottom: 100
	},
	content_gumb:{
		backgroundColor: "#F89C2E",
		border: "none",
		padding: "10px 75px",
		color: "#fff",
		borderRadius: 4,
		fontSize: 16,
		letterSpacing: 2,
		fontWeight: 300,
		display: "inline-block",
		'@media (max-width: 370px)':{
			padding: "10px 45px",
			fontSize: 13,
			letterSpacing: 1,
		},
	},

};

export default Radium(Objave);