import React, {useEffect, useState} from 'react'
import Radium from 'radium';

const Odvetniki = () => {

	const [je1, setJe1] = useState(null)
	const [je2, setJe2] = useState(null)
	const [je11, setJe11] = useState(null)
	const [je22, setJe22] = useState(null)

	useEffect(() => {
		setJe1(
			<a onMouseOver={handleMouseOver1} href="./assets/cards/Tanja_Glušič.vcf" download style={styles.icon} key={'few'}>
				<div key={'fewew'} style={styles.icon_img}>
					<img key={'fdewfewew'} style={styles.icon_img_wrapper} src="./assets/icons/download.svg" alt="download_icon" />
				</div>
				<div key={'few3232'} style={styles.container_2_vizitka}>VIZITKA</div>
			</a>
		)
		setJe2(
			<a key={'cdsdfs'} onMouseOver={handleMouseOver2} href="./assets/cards/Janina_Košnik.vcf" download  style={styles.icon}>
				<div key={'few221'} style={styles.icon_img}>
					<img key={'fe32fofwd'} style={styles.icon_img_wrapper} src="./assets/icons/download.svg" alt="download_icon" />
				</div>
				<div key={'fefds2w'} style={styles.container_2_vizitka}>VIZITKA</div>
			</a>
		)
		setJe11(
			<a onMouseOver={handleMouseOver1} href="./assets/cards/Tanja_Glušič.vcf" download style={styles.icon} key={'fews'}>
				<div key={'fewew'} style={styles.icon_img}>
					<img key={'fdewfewewd'} style={styles.icon_img_wrapper} src="./assets/icons/download.svg" alt="download_icon" />
				</div>
				<div key={'few3232'} style={styles.container_2_vizitka}>VIZITKA</div>

			</a>
		)
		setJe22(
			<a key={'cdsdfdsas'} onMouseOver={handleMouseOver2} href="./assets/cards/Janina_Košnik.vcf" download  style={styles.icon}>
				<div key={'few221das'} style={styles.icon_img}>
					<img key={'fe32fofw'} style={styles.icon_img_wrapper} src="./assets/icons/download.svg" alt="download_icon" />
				</div>
				<div key={'fefds2w'} style={styles.container_2_vizitka}>VIZITKA</div>

			</a>
		)
		// eslint-disable-next-line
	}, [])

	function handleMouseOver1(){
		setJe1(
			<a key="3ewda" onMouseOut={handleMouseOut1} href="./assets/cards/Tanja_Glušič.vcf" download  style={styles.icon2}>
				<div style={styles.icon_img}>
					<img style={styles.icon_img_wrapper} src="assets/icons/arrDown.png" alt="download_icon" />
				</div>
			</a>
		)
		setJe11(
			<a key="3ew" onMouseOut={handleMouseOut1} href="./assets/cards/Tanja_Glušič.vcf" download  style={styles.icon2}>
				<div style={styles.icon_img}>
					<img key="ds" style={styles.icon_img_wrapper} src="assets/icons/arrDown.png" alt="download_icon" />
				</div>
			</a>
		)
	}
	function handleMouseOver2(){
		setJe2(
			<a key="4eqdsa" onMouseOut={handleMouseOut2} href="./assets/cards/Janina_Košnik.vcf" download  style={styles.icon2}>
				<div style={styles.icon_img}>
					<img style={styles.icon_img_wrapper} src="assets/icons/arrDown.png" alt="download_icon" />
				</div>
			</a>
		)
		setJe22(
			<a key="4eq" onMouseOut={handleMouseOut2} href="./assets/cards/Janina_Košnik.vcf" download  style={styles.icon2}>
				<div style={styles.icon_img}>
					<img key="dsfds" style={styles.icon_img_wrapper} src="assets/icons/arrDown.png" alt="download_icon" />
				</div>
			</a>
		)
	}

	function handleMouseOut1(){
		setJe1(
			<a key="5dasdsa" onMouseOver={handleMouseOver1} href="./assets/cards/Tanja_Glušič.vcf" download  style={styles.icon}>
				<div style={styles.icon_img}>
					<img key="ds0" style={styles.icon_img_wrapper} src="./assets/icons/download.svg" alt="download_icon" />
				</div>
				<div style={styles.container_2_vizitka}>VIZITKA</div>

			</a>
		)
		setJe11(
			<a key="5das" onMouseOver={handleMouseOver1} href="./assets/cards/Tanja_Glušič.vcf" download  style={styles.icon}>
				<div style={styles.icon_img}>
					<img key="ds32" style={styles.icon_img_wrapper} src="./assets/icons/download.svg" alt="download_icon" />
				</div>
				<div style={styles.container_2_vizitka}>VIZITKA</div>

			</a>
		)
	}
	function handleMouseOut2(){
		setJe2(
			<a key="bhk6" onMouseOver={handleMouseOver2} href="./assets/cards/Janina_Košnik.vcf" download  style={styles.icon}>
				<div style={styles.icon_img}>
					<img key="ds422" style={styles.icon_img_wrapper} src="./assets/icons/download.svg" alt="download_icon" />
				</div>
				<div style={styles.container_2_vizitka}>VIZITKA</div>

			</a>
		)
		setJe22(
			<a key="6" onMouseOver={handleMouseOver2} href="./assets/cards/Janina_Košnik.vcf" download  style={styles.icon}>
				<div style={styles.icon_img}>
					<img key="dsgwe" style={styles.icon_img_wrapper} src="./assets/icons/download.svg" alt="download_icon" />
				</div>
				<div style={styles.container_2_vizitka}>VIZITKA</div>

			</a>
		)
	}


	return(
		<div style={styles.wrapper}>
			<div style={styles.container}>
				<div style={styles.title}>
					Odvetniki & strokovnjaki
				</div>
				<div key="1dsa" style={styles.info}>
					<div style={styles.container_1_2}>
						<div style={styles.container_1_textWrapper}>
							<div style={styles.container_1_name}>Tanja Glušič</div>
							<div style={styles.container_1_position}>Odvetnica, Partner</div>
							<div style={styles.container_1_flexWrapper}>
								<div style={styles.container_1_izkusnje}>
									<div style={styles.container_1_naslov}>Izkušnje</div>
									<div key="1asd" style={styles.container_1_text}>-Pravni svetovalec, Ministrstvo za gospodarstvo</div>
									<div key="2dasda" style={styles.container_1_text}>-Pripravnica, Višje sodišče v Ljubljani</div>
									<div key="4dsad" style={styles.container_1_text}>-Odvetnica, Odvetniška družba Čeferin</div>
									<div key="5fd" style={styles.container_1_text}>-Odvetnica, partner, Odvetniška družba Glušič</div>
								</div>
								<div style={styles.container_1_izobrazba}>
									<div style={styles.container_1_naslov}>Izobrazba</div>
									<div key="1mj" style={styles.container_1_text}>-Pravniški državni izpit, Republika Slovenija, Ministrstvo za pravosodje</div>
									<div key="2i" style={styles.container_1_text}>-univ. dipl. pravnica, Univerza v Ljubljani, Pravna fakulteta</div>
								</div>
							</div>
							<div key="dascm" style={styles.vizitkaOnPicture}>
								{je1}
							</div>
						</div>

					</div>


					<div style={styles.container_1_flexWrapper2}>
						<div style={styles.container_1_izkusnje}>
							<div style={styles.container_1_naslov}>Izkušnje</div>
							<div key="1g" style={styles.container_1_text}>-Pravni svetovalec, Ministrstvo za gospodarstvo</div>
							<div key="2d" style={styles.container_1_text}>-Pripravnica, Višje sodišče v Ljubljani</div>
							<div key="3f" style={styles.container_1_text}>-Odvetniška kandidatka, Odvetnik Gregor Zupančič</div>
							<div key="4r" style={styles.container_1_text}>-Odvetnica, Odvetniška družba Čeferin</div>
							<div key="5b" style={styles.container_1_text}>-Odvetnica, partner, Odvetniška družba Glušič</div>
						</div>
						<div style={styles.container_1_izobrazba}>
							<div style={styles.container_1_naslov}>Izobrazba</div>
							<div key="1d" style={styles.container_1_text}>-Pravniški državni izpit, Republika Slovenija, Ministrstvo za pravosodje</div>
							<div key="2r" style={styles.container_1_text}>-univ. dipl. pravnica, Univerza v Ljubljani, Pravna fakulteta</div>
						</div>
					</div>




					<div style={styles.container_2}>
						<div style={styles.container_citat}>
							<div style={styles.container_2_text}>
								V svoji dosedanji praksi je opravljala delo tako v gospodarstvu kot v državni upravi, v Sloveniji in v tujini. Je ustanoviteljica Odvetniške družbe Glušič in s svojim strokovnim znanjem in energijo strankam nudi učinkovite rešitve za uveljavljanje njihovih pravic.
							</div>
							<div key="dsaddsafs" style={styles.vizitkaOffPicture}>
								{je11}
							</div>
						</div>
					</div>


				</div>


				<div key="q2" style={styles.info}>

					<div style={styles.container_1_1}>
						<div style={styles.container_1_textWrapper}>
							<div style={styles.container_1_name}>Janina Košnik Vrtač</div>
							<div style={styles.container_1_position}>Odvetnica</div>
							<div style={styles.container_1_flexWrapper}>
								<div style={styles.container_1_izkusnje}>
									<div style={styles.container_1_naslov}>Izkušnje</div>
									<div key="w1" style={styles.container_1_text}>-Pripravnica, Višje sodišče v Ljubljani</div>
									<div key="ewq2" style={styles.container_1_text}>-Odvetniška pripravnica, Odvetnik Tadej Vodičar</div>
									<div key="eqw3" style={styles.container_1_text}>-Odvetnica, Odvetniška družba Glušič</div>
								</div>
								<div style={styles.container_1_izobrazba}>
									<div style={styles.container_1_naslov}>Izobrazba</div>
									<div key="eqw1" style={styles.container_1_text}>-Pravniški državni izpit, Republika Slovenija, Ministrstvo za pravosodje</div>
									<div key="weq2" style={styles.container_1_text}>-univ. dipl. pravnica, Univerza v Ljubljani, Pravna fakulteta</div>
								</div>
							</div>
							<div key="yorfrf" style={styles.vizitkaOnPicture}>
								{je2}
							</div>
						</div>

					</div>


					<div style={styles.container_1_flexWrapper2}>
						<div style={styles.container_1_izkusnje}>
							<div style={styles.container_1_naslov}>Izkušnje</div>
							<div key="ewq1" style={styles.container_1_text}>-Pripravnica, Višje sodišče v Ljubljani</div>
							<div key="ewqewqe2" style={styles.container_1_text}>-Odvetniška pripravnica, Odvetnik Tadej Vodičar</div>
							<div key="3e" style={styles.container_1_text}>-Odvetnica, Odvetniška družba Glušič</div>
						</div>
						<div style={styles.container_1_izobrazba}>
							<div style={styles.container_1_naslov}>Izobrazba</div>
							<div key="ewq1" style={styles.container_1_text}>-Pravniški državni izpit, Republika Slovenija, Ministrstvo za pravosodje</div>
							<div key="ewqeq2" style={styles.container_1_text}>-univ. dipl. pravnica, Univerza v Ljubljani, Pravna fakulteta</div>
						</div>
					</div>



					<div style={styles.container_2}>
						<div style={styles.container_citat}>
							<div style={styles.container_2_text}>
								Svoje prve delovne izkušnje je pridobivala kot sodniška pripravnica na Okrožnem in Okrajnem sodišču v Kranju, svojo pot pa nadaljuje v Odvetniški družbi Glušič, kjer je nepogrešljiva članica ekipe.
							</div>
							<div key="9cvsd" style={styles.vizitkaOffPicture}>
								{je22}
							</div>
						</div>
					</div>


				</div>
			</div>
		</div>
	)
}


const styles = {
	wrapper:{
		position:"relative",
		backgroundColor:"#fff",
		width: "100%",
		paddingTop:70,
		'@media (max-width: 450px)': {
			paddingTop: 30
	    },
	},
	container:{
		margin: "0px auto",
		positon: "relative",
	},
	title:{
		textAlign: "center",
		fontSize: 40,
		fontWeight: 200,
		color: "#142153",
		marginBottom: 80,
		'@media (max-width: 1150px)': {
	      	marginBottom: 50
	    },
	    '@media (max-width: 775px)': {
	      	fontSize: 35,
	      	marginBottom: 30
	    },
	    '@media (max-width: 340px)': {
	      	fontSize: 30,
	      	marginBottom: 20
	    },
	},

	container_1_1:{
		minHeight: 420,
		backgroundImage: "url(./assets/pictures/SLIKAJANINA_reurejena.png)",
		backgroundSize: "cover",
		'@media (max-width: 1350px)': {
	    backgroundPosition: "40% 0%"
    },
    '@media (max-width: 1150px)': {
	    backgroundPosition: "60% 0%"
    },
    '@media (max-width: 860px)': {
	    backgroundPosition: "65% 0%"
    },
    '@media (max-width: 650px)': {
	    minHeight: 350,
    },
    '@media (max-width: 500px)': {
      backgroundPosition: "70% 0%"
    },
    '@media (max-width: 390px)': {
      backgroundPosition: "69% 0%"
	  },
	},
	container_1_2:{
		minHeight: 350,
		backgroundImage: "url(./assets/pictures/GLUSIC_DOLGA.png)",
		backgroundSize: "cover",
		'@media (max-width: 1350px)': {
	    backgroundPosition: "40% 0%"
    },
    '@media (max-width: 1150px)': {
	    backgroundPosition: "60% 0%"
    },
    '@media (max-width: 860px)': {
	    backgroundPosition: "65% 0%"
    },
    '@media (max-width: 650px)': {
	    minHeight: 350,
    },
    '@media (max-width: 500px)': {
      	backgroundPosition: "70% 0%"
    },
    '@media (max-width: 390px)': {
      	backgroundPosition: "69% 0%",
    },
	},
	container_1_textWrapper:{
		width: "calc(100% - 60px)",
		margin: "0px auto",
		maxWidth:1000,
	},
	container_1_name:{
		fontSize: 40,
		fontWeight: 300,
		color: "#F5A623",
		paddingTop: 50,
		
		'@media (max-width: 1120px)': {
	      	paddingTop: 30
	    },
	    '@media (max-width: 1030px)': {
	      	fontSize: 35
	    },
	    '@media (max-width: 910px)': {
	      	fontSize: 30
	    },
	    '@media (max-width: 850px)': {
	      	fontSize: 25
	    },
	    '@media (max-width: 760px)': {
	      	paddingTop: 11
	    },
	},
	container_1_position:{
		fontSize: 19,
		fontWeight: 300,
		color: "#243353",
		fontStyle: "italic",
	},
	container_1_flexWrapper:{
		display: "flex",
		flexFlow: "column nowrap",
		justifyContent: "space-between",
		marginTop: 20,
		paddingBottom:40,
		width: "80%",
		'@media (max-width: 1120px)': {
			width: "60%",
	    },
	    '@media (max-width: 980px)': {
			width: "65%",
	      	marginTop: "5%"
	    },
	    '@media (max-width: 650px)': {
	    	paddingBottom:20,
	    	width: "60%",
    	},
	   	'@media (max-width: 500px)': {
	      	display: "none",
	    },
	    

	},
	vizitkaOffPicture:{
		'@media (max-width: 500px)': {
	      	display: "none",
	    },
	},
	vizitkaOnPicture:{
		marginTop:40,
		'@media (min-width: 501px)': {
	      	display: "none",
	    },
	},
	container_1_flexWrapper2:{
		display: "flex",
		flexFlow: "column nowrap",
		justifyContent: "space-between",
		width: "calc(100% - 40px)",
		marginBottom:0,
		marginRight:"auto",
		marginLeft:"auto",
		marginTop: 30,

   	'@media (min-width: 501px)': {
      	display: "none",
      	width: "100%",
    },
	    
	},
	container_1_izobrazba:{
		position: "relative",
		marginTop:10,
		marginBottom:30,
	},
	container_1_naslov:{
		fontSize: 23,
		fontWeight: 300,
		color: "#F5A623",
		'@media (max-width: 910px)': {
	    fontSize: 18
	  },
	},
	container_1_text:{
		fontSize: 15,
		fontWeight: 300,
		color: "#243353",
		'@media (max-width: 910px)': {
	    fontSize: 13
	  },
	},



	//container_2
	container_2:{
		width: "calc(100% - 40px)",
		margin: "30px auto",
		maxWidth:1300,
	},
	container_citat:{
		display:"flex",
		justifyContent:"space-between",
		alignItems:"center",
		'@media (max-width: 470px)': {
	     flexDirection:"column",
	  },
	},

	container_2_text:{
		
		fontSize: 18,
		fontWeight: 300,
		color: "#142153",
		display: "inline-block",
	  	marginRight: 105,
		'@media (max-width: 1120px)': {
	    fontSize: 17
	  },
	  '@media (max-width: 750px)': {
			marginRight: 50,
	  },
	  '@media (max-width: 500px)': {
			marginRight: 0,
	  },
	},
	container_2_naslov:{
		fontWeight: 400,
		fontSize: 19,
		display: "inline-block",
		paddingRight: 5,
		'@media (max-width: 1120px)': {
	      	fontSize: 19
	    },
	    '@media (max-width: 980px)': {
			fontSize: 16
	    },
	},
	icon:{
		width:"60px",
		height:"60px",
		display:"flex",
		justifyContent:"center",
		itemsAlign:"center",
		color: "#F5A623",
		textDecoration:"none",
		flexDirection:"column",
		border: "2px solid #F5A623",
		borderRadius: 4,
		position: "relative",
		background:"#fff",
	},
	icon2:{
		width:"60px",
		height:"60px",
		display:"flex",
		justifyContent:"center",
		itemsAlign:"center",
		color: "#F5A623",
		textDecoration:"none",
		flexDirection:"column",
		border: "2px solid #F5A623",
		borderRadius: 4,
		position: "relative",
		background:"#F5A623"
	},
	icon_img_wrapper:{
		width:30,
	},
	icon_img:{
		position: "relative",
		textAlign:"center",
	},
	container_2_vizitka:{
		fontSize: 10.56,
		textAlign: "center",
		color: "#F5A623",
		paddingTop: 5,
		letterSpacing: 0.5
	},
	//end container_2

};

export default Radium(Odvetniki);