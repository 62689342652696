import React, {useState} from 'react';
import Radium from 'radium';
import MailForm from "../components/mailForm.js"

const Pisarna = () => {

	const [text, setText] = useState({
		open1: false
	})

	function handleClick1(){
		setText({open1: !text.open1,
			boxTitle:"Delovno pravo",
			boxText1:"- Priprava internih aktov družbe",
			boxText2:"- Odpoved pogodbe o zaposlitvi (redna, izredna)",
			boxText3:"- Pogodbe o zaposlitvi",
			boxText4:"- Delovno razmerje za določen čas",
			boxText5:"- Letni dopust",
			boxText6:"- Obvestilo o kršitvah iz delovnega razmerja",
			boxText7:"- Starejši delavci",
			boxText8:"- Sporazum o prenehanju delovnega razmerja",
			boxText9:"- Sindikati in sodelovanje pri upravljanju",
			boxText10:"- Kolektivne pogodbe - podjetniške, dejavnosti",
			boxText11:"- Sistemizacija delovnih mest",
			boxText12:"- Delovni čas",
			boxText13:"- Mobbing",
			boxText14:"- Nadzor bolniškega staleža",
			boxText15:"- Osebni podatki zaposlenih",
		})
	}

	function handleClick2(){
		setText({open1: !text.open1,
			boxTitle:"Javno naročanje & javno zasebno partnerstvo",
			boxText1:"- Svetovanje v postopkih javnega naročanja",
			boxText2:"- Postopki javno zasebnega partnerstva",
			boxText3:"- Izbira najprimernejše oblike javno zasebnega partnerstva",
			boxText4:"- Priprava razpisne dokumentacije za javne naročnike",
			boxText5:"- Izvedba postopka javno zasebnega partnerstva",
			boxText6:"- Priprava revizijskih zahtevkov",
			boxText7:"- Vpogled v ponudbe",
			boxText8:"- Dostop do informacij javnega značaja",
			boxText9:"- Priprava / pregled pogodbe o javno zasebnem partnerstvu",
			boxText10:"- Vodenje in izvedba postopka za pridobitev zasebnega partnerja (priprava razpisne dokumentacije, izvedba konkurenčnega dialoga",
			boxText11:"- Priprava ponudbe",
			boxText12:"- Pregled javnega razpisa",
			boxText13:"",
			boxText14:"",
			boxText15:"",
		})
	}

	function handleClick3(){
		setText({open1: !text.open1,
			boxTitle:"Varstvo osebnih podatkov",
			boxText1:"- Novosti GDPR!",
			boxText2:"- Priprava dokumentacije",
			boxText3:"- Pregled pravnega stanja",
			boxText4:"- Priprava internih pravilnikov",
			boxText5:"- Priprava pogodb o pogodbeni obdelavi osebnih podatkov",
			boxText6:"- Videonadzor",
			boxText7:"- Biometrija",
			boxText8:"- Pooblastilo za dostop do osebnih podatkov",
			boxText9:"- Imenovanje DPO (Data Protection Officer) - Pooblaščenca za varstvo osebnih podatkov",
			boxText10:"- Fotografiranje delavcev ",
			boxText11:"- Nepooblaščena objava fotografij",
			boxText12:"- Evidence",
			boxText13:"- Data Protection Impact Assesment",
			boxText14:"- Varstvo zasebnosti delavcev na delovnem mestu",
			boxText15:"",
		})
	}

	function handleClick4(){
		setText({open1: !text.open1,
			boxTitle:"Gradbeništvo",
			boxText1:"- Gradbeni zakon - novosti!",
			boxText2:"- Gradbene pogodbe",
			boxText3:"- FIDIC pogodbe",
			boxText4:"- Gradbeno dovoljenje ",
			boxText5:"- Uporabno dovoljenje",
			boxText6:"- Nakup in prodaja nepremičnin",
			boxText7:"- Upravni postopki",
			boxText8:"- Izvajalci, investitorji, nadzor, podizvajalci, drugi udeleženc",
			boxText9:"- Podizvajalske pogodbe",
			boxText10:"- Najemne pogodbe",
			boxText11:"- Stvarne napake",
			boxText12:"- Ustno naročilo del",
			boxText13:"- Zahtevek za vlaganja",
			boxText14:"- Bančna garancija",
			boxText15:"- Odgovornost projektantov",
		})
	}

	function handleClick5(){
		setText({open1: !text.open1,
			boxTitle:"Nepremičnine",
			boxText1:"- Nakup in prodaja nepremičnin",
			boxText2:"- Upravljanje večstanovanjskih stavb",
			boxText3:"- Upravniki",
			boxText4:"- Upravni postopki",
			boxText5:"- Najemne pogodbe",
			boxText6:"- Nadomestilo za uporabo stavbnega zemljiška",
			boxText7:"- Pravilno ocenjena vrednost nepremičnin pred FURS",
			boxText8:"- Ustanovitev stavbne pravice",
			boxText9:"- Imisije iz nepremičnin",
			boxText10:"- Sprememba načina ogrevanja večstanovanjske stavbe",
			boxText11:"- Izbira izvajalcev v večstanovanjskih stavbah",
			boxText12:"",
			boxText13:"",
			boxText14:"",
			boxText15:"",
		})
	}

	function handleClick6(){
		setText({open1: !text.open1,
			boxTitle:"Varstvo pri delu",
			boxText1:"- Delovna nezgoda (nesreča)",
			boxText2:"- Uveljavljanje odškodnine",
			boxText3:"- Zastopanje pred sodišči, zavarovalnicami, upravnimi organi",
			boxText4:"- Postopek pred inšpektorjem",
			boxText5:"- Raziskava delovne nezgode",
			boxText6:"- Mobbing / trpinčenje na delovnem mestu",
			boxText7:"- Delo pod vplivom alkohola /alkohol na delovnem mestu",
			boxText8:"- Varno delo",
			boxText9:"- Bolniški stalež",
			boxText10:"- Pravice pri ZZZS",
			boxText11:"- Pravice pri ZPIZ",
			boxText12:"",
			boxText13:"",
			boxText14:"",
			boxText15:"",
		})
	}

	const box_info_absolute_wrapper_state1 = text.open1 ? styles.box_info_absolute_wrapper_open1 : styles.box_info_absolute_wrapper_close1;

	return(
		<div style={styles.wrapper}>
			<div style={styles.container}>
				<div style={styles.content_1}>
					<div style={styles.naslov}>
						Glavna področja dela
					</div>

				</div>
				<div style={styles.content_2_flexWrapper}>
					<div key="1" style={[styles.box_info_absolute_wrapper, box_info_absolute_wrapper_state1]}>
						<div style={styles.box_info_absolute}>
							<div style={styles.box_info_absolute_close_button_wrapper} onClick={handleClick1}>
								<img alt="ikona_vec_close" style={styles.close_button}
										 src="../assets/icons/ikona_vec_close.svg" />
							</div>
							<div style={styles.box_info_absolute_title}> {text.boxTitle}</div>
							<div style={styles.boxAbsoluteFlex}>
								<div key="1" style={styles.boxAbsoluteFlexRow} >

									<div key="1" style={styles.box_info_absolute_text}>{text.boxText1}</div>
									<div key="2" style={styles.box_info_absolute_text}>{text.boxText2}</div>
									<div key="3" style={styles.box_info_absolute_text}>{text.boxText3}</div>
									<div key="4" style={styles.box_info_absolute_text}>{text.boxText4}</div>
									<div key="5" style={styles.box_info_absolute_text}>{text.boxText5}</div>
									<div key="6" style={styles.box_info_absolute_text}>{text.boxText6}</div>
									<div key="7" style={styles.box_info_absolute_text}>{text.boxText7}</div>
									<div key="8" style={styles.box_info_absolute_text}>{text.boxText8}</div>
								</div>
								<div key="2" style={styles.boxAbsoluteFlexRow} >
									<div key="1" style={styles.box_info_absolute_text}>{text.boxText9}</div>
									<div key="2" style={styles.box_info_absolute_text}>{text.boxText10}</div>
									<div key="3" style={styles.box_info_absolute_text}>{text.boxText11}</div>
									<div key="4" style={styles.box_info_absolute_text}>{text.boxText12}</div>
									<div key="5" style={styles.box_info_absolute_text}>{text.boxText13}</div>
									<div key="6" style={styles.box_info_absolute_text}>{text.boxText14}</div>
									<div key="7" style={styles.box_info_absolute_text}>{text.boxText15}</div>
								</div>
							</div>
						</div>
					</div>
					<div key="2" onClick={handleClick2} style={styles.content_2_flexWrapper_box}>


						<div  style={styles.content_2_flexWrapper_box_padding}>
							<div style={styles.content_2_flexWrapper_box_text}>
								Javno naročanje & javno zasebno partnerstvo
							</div>
							<div style={styles.content_2_flexWrapper_box_more} >
								<img src = "../assets/icons/ikona_vec.svg" alt = "icon_more" />

							</div>
						</div>
					</div>
					<div key="3" onClick={handleClick4} style={[styles.content_2_flexWrapper_box, styles.content_2_flexWrapper_box_secondary]}>


						<div  style={styles.content_2_flexWrapper_box_padding}>
							<div style={styles.content_2_flexWrapper_box_text}>
								Gradbeništvo
							</div>
							<div style={styles.content_2_flexWrapper_box_more} >
								<img src = "../assets/icons/ikona_vec.svg" alt = "icon_more" />

							</div>
						</div>
					</div>
					<div key="4" onClick={handleClick1} style={styles.content_2_flexWrapper_box}>

						<div  style={styles.content_2_flexWrapper_box_padding}>
							<div style={styles.content_2_flexWrapper_box_text}>
								Delovno pravo
							</div>
							<div style={styles.content_2_flexWrapper_box_more} >
								<img src = "../assets/icons/ikona_vec.svg" alt = "icon_more" />

							</div>
						</div>
					</div>
					<div key="5" onClick={handleClick5} style={[styles.content_2_flexWrapper_box, styles.content_2_flexWrapper_box_secondary]}>

						<div  style={styles.content_2_flexWrapper_box_padding}>
							<div style={styles.content_2_flexWrapper_box_text}>
								Nepremičnine
							</div>
							<div style={styles.content_2_flexWrapper_box_more} >
								<img src = "../assets/icons/ikona_vec.svg" alt = "icon_more" />

							</div>
						</div>
					</div>
					<div key="6" onClick={handleClick6} style={styles.content_2_flexWrapper_box}>


						<div  style={styles.content_2_flexWrapper_box_padding}>
							<div style={styles.content_2_flexWrapper_box_text}>
								Varstvo pri delu
							</div>
							<div style={styles.content_2_flexWrapper_box_more}>
								<img src = "../assets/icons/ikona_vec.svg" alt = "icon_more" />

							</div>
						</div>
					</div>
					<div key="7" onClick={handleClick3} style={[styles.content_2_flexWrapper_box, styles.content_2_flexWrapper_box_secondary]}>

						<div  style={styles.content_2_flexWrapper_box_padding}>
							<div style={styles.content_2_flexWrapper_box_text}>
								Varstvo osebnih podatkov
							</div>
							<div style={styles.content_2_flexWrapper_box_more} >
								<img src = "../assets/icons/ikona_vec.svg" alt = "icon_more" />

							</div>
						</div>
					</div>
				</div>
				<div style={styles.content_3}>
					<div key="1" style={styles.naslov}>
						Druga področja
					</div>
					<div key="2" style={styles.podnaslov}>
						Nepremičninsko pravo
					</div>
					<div key="3" style={styles.besedilo}>
						Odvetniška družba se ukvarja z vsemi vidiki nepremičninskih poslov, ki se nanašajo na pridobitev, odtujitev (prodaja, darilo) in tudi financiranje nepremičnin. Svetujemo in zastopamo tako gospodarske subjekte pri kompleksnih nepremičninskih poslih kot tudi posameznike, da si v največji meri zavarujejo svoje pravice pri sklepanju nepremičninskih transakcij. Pri tem svetujemo tudi na področju vpisov nepremičnin v zemljiško knjigo, priprave pogodb, priposestvovanja, služnosti, pridobitve upravnih dovoljenj.
					</div>
					<div key="4" style={styles.podnaslov}>Pravo varstva potrošnikov</div>
					<div key="5" style={styles.besedilo}>Odvetniška družba Glušič s svojim strokovnim znanjem in mednarodnimi izkušnjami pripravlja in svetuje tako gospodarskim in drugim subjektom pri pripravi celovitih rešitev za skladno delovanje v razmerju do potrošnikov, vključno s pripravo ustreznih dokumentov za namen varstva osebnih podatkov. Prav tako odvetniška družba nudi pravno pomoč tudi potrošnikom pri uveljavljanju njihovih pravic, vključno z nakupi na daljavo (npr. na svetovnem spletu) in odškodninami in naslova zamude letal. Opravljamo storitve na področju stvarnih in drugih napak, uveljavljanja garancij, nepoštenih poslovnih praks in pogojev, potrošniških kreditov. Za učinkovito zagotavljanje storitve sodelujemo tudi z organizacijami v tujini, saj se velik del nakupov potrošnikov opravi tudi v Evropski Uniji in tretjih državah.  </div>

					<div key="6" style={styles.podnaslov}>Odškodninsko pravo</div>
					<div key="7" style={styles.besedilo}>Odvetniška družba se aktivno ukvarja s svetovanjem in zastopanjem strank v odškodninskih postopkih, pri čemer pa poseben poudarek dajemo področju odškodnin iz nezgod pri delu. Zastopamo tako delavce kot delodajalce, slednjim pa glede na nastanek nezgode nudimo tudi svetovanje in celostni pregled ter pripravo ustreznih ukrepov na področju varstva pri delu. </div>

					<div key="8" style={styles.podnaslov}>Dedovanje</div>
					<div key="9" style={styles.besedilo}>Strankam svetujemo pri dedovanju, pripravljamo pogodbe o dosmrtnem preživljanju, izročitvi premoženja oziroma darilnih pogodb. Stranke zastopamo v zapuščinskih postopkih  kot tudi jih zastopamo v zapuščinskih postopkih, sestavljamo oporoke. </div>

					<div key="10" style={styles.podnaslov}>Družinsko pravo</div>
					<div key="11" style={styles.besedilo}>Z izkušnjami s področja družinskih razmerij, strankam nudimo pravno svetovanje pri razvezah in razpadih izvenzakonskih skupnosti. Pri tem se ukvarjamo tako z reševanjem premoženjsko pravnih razmerjih med zakoncema oziroma partnerjema kot tudi z vprašanjem dodelitve otrok v vzgojo, varstvo in oskrbo, glede preživljanja otrok in stikov z otroci. </div>

					<div key="12" style={styles.podnaslov}>Prekrški</div>
					<div key="13" style={styles.besedilo}>Zastopamo tako pravne kot fizične osebe v raznovrstnih prekrškovnih postopkih pred prekrškovnimi organi in pred sodiščem od postopkov vezano na prekoračene hitrosti do prekrškovnih postopkov vezano na Finančno upravo Republike Slovenije. </div>

					<div key="14" style={styles.podnaslov}>FIDIC pogodbeni pogoji</div>
					<div key="15" style={styles.besedilo}>Odvetniška družba si je z izvedbo več uspešnih projektov pridobila dragocene izkušnje na področju priprave svetujemo pri pripravi posebnih pogojev FIDIC pogodb ter pri tem sodelujemo s priznanimi strokovnjaki na posameznih področjih za zagotovitev največje kakovosti storitve za naše stranke.</div>

					<div key="16" style={styles.podnaslov}>Upravljanje stavb</div>
					<div key="17" style={styles.besedilo}>Upravnikom večstanovanjskih stavb oziroma kotlovnic v večstanovanjskih stavbah svetujemo na vseh področjih delovanja od zamenjave upravnika, sklepanja pogodb o upravljanju, pripravi obračunov stroškov v večstanovanjskih stavbah, pripravi splošnih pogojev za dobavo toplote, zastopanju pred sodiščem za izterjavo stroškov od posameznih etažnih lastnikov in pri pripravi drugih spremljajočih storitev.  </div>

					<div key="18" style={styles.podnaslov}>Pokojninski in invalidski postopki</div>
					<div key="19" style={styles.besedilo}>Odvetniška družba svetuje tudi v invalidskih in pokojninskih postopkih, vezano na pridobitev pravice do pokojnine, pravice do dela s krajšim delovnim časom, rehabilitacije in drugih pravicah pred Zavod za pokojninsko in invalidsko zavarovanje oziroma pred Zavodom za zdravstveno zavarovanje in Socialnim sodiščem. </div>
				</div>
			</div>
			<MailForm />
			<div style={styles.picture}>
				<div style={styles.picture_container}>
					Z našimi strankami gradimo dolgoročno sodelovanje
				</div>
			</div>
		</div>
	)
}

const styles = {
	wrapper:{

		position:"relative",
		backgroundColor:"#fff",
		paddingTop:70,
		'@media (max-width: 630px)': {
			paddingTop: 40
	    },
	},
	container:{
		width: "calc(100% - 60px)",
		margin: "0px auto",
		positon: "relative",
	},
	content_1:{
		maxWidth:950,
		//height: "30vh",
		margin: "20px auto",
		marginBottom:60,

	},
	naslov:{
		fontSize: 41,
		fontWeight: 300,
		marginBottom: 30,
		color: "#243353",
		'@media (max-width: 800px)': {
			fontSize: 35,
			marginBottom: 20
	    },
	    '@media (max-width: 670px)': {
			textAlign: "center"
	    },
	    '@media (max-width: 520px)': {
			fontSize: 30,
	    },

	},





	//box_info

	box_info_absolute:{
		color:"#fff",
	},
	box_info_absolute_wrapper:{
		position:"absolute",
		top:-1,
		left:0,
		zIndex:2,
		padding:20,
		borderRadius:4,
		backfaceVisibility: "hidden",
    transform: "translateZ(0) scale(1.0, 1.0)",

		width:"calc(100% - 0px)",
		height:"calc(100% - 57px)",
		background:"#F89C2E",
		transition:"0.2s",
		'@media (max-width: 915px)': {
			height:"calc(100% - 77px)",
		},
		'@media (max-width: 655px)': {
			padding:0,
			width:"calc(100% + 2px)",
			height:"calc(100% + 2px)",
		},
		'@media (max-width: 420px)': {
			height:"calc(100% + 2px)",
		},
	},
	box_info_absolute_title:{
		fontSize:37,
		margin:30,
		marginBottom:0,
		marginTop:10,
		'@media (max-width: 1200px)': {
			fontSize: 30
		},
		'@media (max-width: 1060px)': {
			fontSize: 25,
			marginTop:0,
		},
		'@media (max-width: 670px)': {
			marginBottom:40,
			marginTop:80,
		},
		'@media (max-width: 350px)': {
			fontSize: 20
		},
		
	},
	box_info_absolute_text:{
		fontSize:15,
		marginBottom:19,
		fontWeight:600,
		'@media (max-width: 1000px)': {
			fontSize: 15,
			marginBottom:9,

		},
		'@media (max-width: 700px)': {

		},
		'@media (max-width: 670px)': {
			lineHeight:1.3,
			marginBottom:12,
		},
		'@media (max-width: 370px)': {
			marginBottom:10,
		},
	},


	box_info_absolute_wrapper_open1: {
		transform:"translateY(0%) scale(1,1)",
	},
	box_info_absolute_wrapper_close1: {
		transform:"translateY(-50%) scale(1,0)",
	},



	box_info_absolute_close_button_wrapper:{
		position:"absolute",
		zIndex:10000,
		top:0,
		right:30,
		backfaceVisibility: "hidden",
        transform: "translateY(0) rotate(45deg)",
        cursor:"pointer",
    '@media (max-width: 670px)': {
			top:-50,
		},
	},
	close_button:{
		cursor:"pointer",
		height:35,
	},


	boxAbsoluteFlex:{
		display:"flex",
		'@media (max-width: 670px)': {
			flexDirection:"column",
		},
	},
	boxAbsoluteFlexRow:{
		margin:30,
		width:"calc(50% - 30px)",
		'@media (max-width: 670px)': {
			margin:30,
			marginTop:0,
			marginBottom:0,
			width:"calc(100% - 60px)",
		},
	},



	//end box_info



	podnaslov:{
		fontSize: 22,
		marginBottom: 15,
		marginTop: 20,
		color: "#243353",
		'@media (max-width: 800px)': {
			fontSize: 22
	    },
	    '@media (max-width: 670px)': {
			textAlign: "center"
	    },
	    '@media (max-width: 500px)': {
			marginTop: 30
	    },
	},
	besedilo:{
		lineHeight:1.4,
		letterSpacing:0.6,
		fontSize: 17,
		fontWeight: 300,
		color: "#243353",
		'@media (max-width: 800px)': {
			fontSize: 16
	    },
	    '@media (max-width: 670px)': {
			textAlign: "center"
	    },
	    '@media (max-width: 400px)': {
			fontSize: 14
	    },
	},

	content_2_flexWrapper:{
		margin: "0px auto",
		maxWidth:950,
		display: "flex",
		flexFlow: "row wrap",
		justifyContent: "space-between",
		marginBottom: 20,
		'@media (max-width: 670px)': {
			flexFlow: "column wrap",
			alignItems: "center"
	    },
	    '@media (max-width: 340px)': {
			
	    },
	},
	content_2_flexWrapper_box:{
		width: "47%",
		display:"flex",
		alignItems:"center",
		justifyContent:"center",
		position: "relative",
		height:100,
		cursor:"pointer",
		border: "1px solid #243353",
		borderRadius: 4,
		marginBottom: 60,
		transition:"0.1s",
		'@media (max-width: 670px)': {
			width: "90%",
			marginBottom: 40,
	    },
	    '@media (max-width: 450px)': {
				width: "100%",
	    },
	    ":hover":{
	    	border: "4px solid #243353",
	    	height:100,
	    	borderRadius: 8,
	    	transform: "scaleX(1.05) scaleY(1.05)",
	    },

	},

	content_2_flexWrapper_box_secondary:{
		'@media (max-width: 670px)': {
			width: "90%",
			order: 2
	    },
	    '@media (max-width: 450px)': {
			width: "100%",
	    },
		
	},
	content_2_flexWrapper_box_padding:{
		display:"flex",
		cursor:"pointer",
		alignItems:"center",
		justifyContent:"space-between",
		width: "90%",
		
	},
	content_2_flexWrapper_box_padding_TR:{
		'@media (max-width: 820px)': {
			paddingTop: "6%",
			paddingBottom: "6%"
		},
		'@media (max-width: 670px)': {
			paddingTop: "7%",
			paddingBottom: "7%"
	    },
	    '@media (max-width: 525px)': {
			paddingTop: "5.5%",
			paddingBottom: "5.5%"
	    },
	},
	content_2_flexWrapper_box_info:{
		position: "absolute",
		display: "none"
	},
	content_2_flexWrapper_box_text:{
		display: "inline-block",
		fontSize: 20,
		color: "#243353",
		'@media (max-width: 885px)': {
			fontSize: 18,
			marginRight: 10
	    },
	    
	},

	content_2_flexWrapper_box_more:{
		display: "inline-block",
		cursor:"pointer",
	},
	content_3:{
		maxWidth:950,
		
		//height: "40vh",
		margin: "0px auto",
		marginBottom: 80,
		'@media (max-width: 550px)': {
			marginBottom: 40,
		},
	},
	
	
	contacts_form_inputWraper_design:{
		borderRadius: 4,
		width: "28%",
		padding: "10px 0px 10px 15px",
		display: "inline-block",
		position: "relative",
		left: -13,
		backgroundColor: "#FFF",
		'@media (max-width: 1150px)': {
			padding: "5px 0px 5px 15px",
			},
			'@media (max-width: 1000px)': {
			padding: "5px 0px 5px 15px"
			},
			'@media (max-width: 820px)': {
			width: "32%"
			},
			'@media (max-width: 700px)': {
			display: "block",
			margin: "0px auto",
			left: 0,
			marginBottom: 10,
			width: "40%"
			},
			'@media (max-width: 600px)': {
			width: "50%"
			},
			'@media (max-width: 450px)': {
			width: "60%"
			},
			'@media (max-width: 370px)': {
			width: "75%"
			},
	},
	contacts_form_input:{
		fontSize: 19,
		fontWeight: 300,
		border: "none",
		borderLeft: "2px solid #F5A623",
		padding: "10px 0px 10px 15px",
		width: "95%",
		outline: "none",
		'@media (max-width: 1000px)': {
			padding: "5px 0px 5px 15px",
			fontSize: 17,
			},	
			'@media (max-width: 820px)': {
			padding: "3px 0px 3px 10px",
			fontSize: 15,
			},
	},

	/*prijava_input:{
		borderRadius: 4,
		fontSize: 19,
		fontWeight: "300",
		width: "29%",
		padding: "17 0 17 40",
		position: "relative",
		left: -20,
		outline: "none",
		boxSizing: "border-box",
		borderLeft: "none"
	},*/
	

	picture:{
		height: "55vh",
		maxHeight:500,
		backgroundSize: "cover",
		backgroundImage: "url(../assets/pictures/Group.png)",
		borderBottom: "7px solid #fff",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		'@media (max-width: 750px)': {
			height: "45vh",
	    },
	    '@media (max-width: 600px)': {
			height: "40vh",
	    },
	},
	picture_container:{
		width: "38%",
		fontSize: 41,
		fontWeight: 300,
		color: "#162A75",
		textAlign: "center",
		'@media (max-width: 1175px)': {
			width:"50%"
	    },
	    '@media (max-width: 1000px)': {
			fontSize: 35
	    },
	    '@media (max-width: 750px)': {
			fontSize: 30
	    },
	    '@media (max-width: 600px)': {
			fontSize: 25,
			width: "70%"
	    },
	},
	


};



export default Radium(Pisarna);