import Radium from 'radium';
import React, {useReducer} from 'react';
import axios from 'axios';

function reducer(state, action) {
  const newState = {...state}
  if (Array.isArray(action.name) && Array.isArray(action.value)) {
    action.name.forEach((item, index) => {
      newState[item] = action.value[index]
    })
  } else {
    newState[action.name] = action.value
  }
  return newState
}


const ContactForm = ({onClick, kje}) => {

  const initialState = {
    name:"",
    email:"",
    tel:"",
    nacin:"",
    location:"home",

    checkbox: false,
    inputOk1: true,
    inputOk2: true,
    inputOk3: true,
    inputOk4: true,
    emailValidate: true,

    startTransition: false,
    nextScreen: false,
    kje:"",
  }

  function setState(name, value) {
    dispatch({name, value})
  }

  const [state, dispatch] = useReducer(reducer, initialState)


  function handleRegister2(){

    if(state.name === ""){
      setState('inputOk1', false)
    } else {
      setState('inputOk1', true)
    }
    if(state.checkbox){
      setState('inputOk4', true)
    } else {
      setState('inputOk4', false)
    }
    setState(['inputOk3', 'emailValidate'], [true, true])
    if(state.tel === ""){
      setState('inputOk2', false)
    } else {
      setState('inputOk2', true)
    }


    if(state.name !== "" && state.tel !== "" && state.checkbox){
      setState(
        ['kje', 'nacin', 'startTransition'],
        [kje, "Odpisali vam bomo v najkrajšem možnem času. V kolikor našega odgovora ne prejmete v roku treh delovnih dni nam to prosim sporočite na elektronski naslov", true]
      )


      setTimeout(() => {
        axios.post('https://email.mtoffice.net/mail2', state).then(response => {
          console.log(response);
        })
          .catch(error => {
            console.log(error);
          });
      },50);
      setTimeout(() => setState('nextScreen', true), 400);
    }
  }

  function handleRegister1(){
    if(state.name === ""){
      setState('inputOk1', false);
    } else {
      setState('inputOk1', true);
    }
    if(state.checkbox){
      setState('inputOk4', true);
    } else {
      setState('inputOk4', false);
    }
    setState('inputOk2', true);

    if(state.email === ""){
      setState('inputOk3', false);
    } else {
      setState('inputOk3', true);
    }
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(state.email)){
      setState('emailValidate', true);
    }else{
      setState('emailValidate', false);
    }
    setTimeout(() => {
      if(state.name !== "" && state.email !== "" && state.checkbox && state.emailValidate){
        setState(
          ['kje', 'nacin', 'startTransition'],
          [kje, "Naš klic lahko pričakujete vsak delovnik med 9:00 in 15:00. V kolikor želite, da vas pokličemo izven časovnega okvira, nam prosim želeni termin sporočite v elektronski obliki na naslov", true]
        )
        setTimeout(() => {
          axios.post('https://email.mtoffice.net/mail2', state).then(response => {
            console.log(response);
          })
            .catch(error => {
              console.log(error);
            });
        },50);
        setTimeout(() => setState('nextScreen', true), 400);
      }
    },10);
  }

  function back(){
    setState(
      ['startTransition', 'nextScreen'],
      [false, false]
    )
  }


  const firstScreenState = state.startTransition ? styles.wrapperOut : styles.wrapperIn;
  const checkboxState = state.inputOk4 ? styles.checkboxOk : styles.checkboxErr;
  const inputOkState1 = state.inputOk1 ? styles.boxInputOk : styles.boxInputError;
  const inputOkState2 = state.inputOk2 ? styles.boxInputOk : styles.boxInputError;
  const inputOkState3 = state.inputOk3 ? styles.boxInputOk : styles.boxInputError;

  return(
    <div style={styles.wrapperMain}>
      {!state.nextScreen ?
        <div style={firstScreenState}>


          <div style={styles.contacts_form_inputWraper}>
            <div style={[styles.contacts_form_inputWraper_design, inputOkState1]}>
              <input
                type="name"
                style={styles.contacts_form_input}
                placeholder="Ime in priimek"
                onChange={e => setState( 'name', e.nativeEvent.target.value )}
              />
            </div>
            <div style={[styles.contacts_form_inputWraper_design, inputOkState2]}>
              <input
                type="tel"
                style={styles.contacts_form_input}
                placeholder="Telefon"
                onChange={e => setState( 'tel', e.nativeEvent.target.value )}
              />
            </div>
            <div style={[styles.contacts_form_inputWraper_design, inputOkState3]}>
              <input
                type="email"
                style={styles.contacts_form_input}
                placeholder="Email"
                onChange={e => setState( 'email', e.nativeEvent.target.value )}
              />
            </div>
            {!state.emailValidate ?
              <div style={styles.redTextEmial}>Prosimo vpišite veljaven email naslov</div>
              :
              null
            }
            <div className="md-checkbox">
              <input onClick={() => setState('checkbox', !state.checkbox)} id="i2" value={state.checkbox} type="checkbox" />
              <label style={checkboxState} htmlFor="i2">strinjam se s pogoji uporabe</label>
            </div>
            <div style={styles.contacts_form_text}>Kako želite da vas kontaktiramo?</div>
            <div style={styles.contacts_form_buttonWrapper}>
              <div style={styles.contacts_form_buttonWrapper_margin}>
                <button key="s" onClick={handleRegister1} style={[styles.contacts_form_button, styles.contacts_form_button_margin]}>EMAIL</button>
                <button key="sdsa" onClick={handleRegister2} style={[styles.contacts_form_button]}>TELEFON</button>
              </div>
              {!state.inputOk1 || !state.inputOk2 || !state.inputOk3 || !state.inputOk4 ?
                <div style={styles.redText}>Prosim izpolnite polja označena z rdečo barvo</div>
                :
                null
              }
            </div>
          </div>
        </div>
        :
        <div style={styles.nextScreenSecond}>
          <div style={styles.textCenter}>{state.name}, hvala za oddano povpraševanje.</div>
          <div style={styles.textCenterSmall}>
            Kontaktirali vas bomo v najkrajšem možnem času.
          </div>
          <svg className="checkmark" xmlns="http://www.w3.org/200/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle_home" cx="26" cy="26" r="25" fill="none"/>
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>


          <div style={styles.back} onClick={back}>Nazaj</div>
        </div>
      }
    </div>
  )
}


const styles = {
  wrapperMain:{
    margin:"0px auto",
    width:"100%",
  },
  closeWrapper: {
    cursor:"pointer",
    position:"relative",

    textAlign:"center",
    float:"right",
    marginTop:-90,
    transition:"0.2s",
    ":hover":{
      opacity:0.6,
    },
    '@media (max-width: 840px)': {
      marginTop:-50,
      marginRight:-10,
    },
    '@media (max-width: 665px)': {
       marginTop:-20,
      marginRight:-10,
    },
    '@media (max-width: 565px)': {
       marginTop:-90,
        marginRight:20,
    },
    '@media (max-width: 520px)': {
       marginTop:-90,
        marginRight:0,
    },
  },
  img: {
    width:20,
  },
  imgText:{
    fontSize:12,
    paddingTop:7,
  },
  wrapperIn: {
    position:"relative",
    opacity:1,
    margin:"0px auto",
    width:"100%",
    transition:"0.5s",
  },
  wrapperOut: {
    position:"relative",
    opacity:0,
    width:"100%",
    margin:"0px auto",
    transition:"0.5s",
  },
  flex:{
    margin:"0px auto",
    maxWidth:460,
    '@media (max-width: 755px)': {
      maxWidth:340,
    },
    '@media (max-width: 590px)': {
      maxWidth:300,
    },
    '@media (max-width: 565px)': {
      maxWidth:400,
    },
  },
  link:{
    textDecoration:"none",
    color:"black",
    padding: 4,
    fontSize:17,
    fontWeight:300,
    webkitTransition:"0.3s  cubic-bezier(0,0,0,5)",
    transition:"0.3s  cubic-bezier(0,0,0,5)",
    display:"block",
    ":hover":{
      color:"#333333",
    }
  },


  nextScreenSecond: {
    transition:"0.6s",
    width:"100%",
    margin:"0px auto",
    opacity:1,
  },

  textCenter: {
    textAlign:"center",
    marginBottom: "20px",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop:60,
    fontWeight:500,
    color:"#142153",
    fontSize:22,
  },
  textCenterSmall: {
    textAlign:"center",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop:"20px",
    marginBottom:25,
    color:"#142153",
    fontSize:17,
  },
  back:{
    color:"#F89C2E",
    fontSize:17,
    cursor:"pointer",
  },


  contacts_form_inputWraper_design:{
    borderRadius: 4,
    
    width: "70%",
    padding: "10px 0px 10px 25px",
    display: "inline-block",
    position: "relative",
    left: 110,
    marginBottom: 20,
    transition: "0.2s ease-in-out",
    '@media (max-width: 900px)': {
      marginRight: "auto",
      marginLeft: "auto",
      marginTop:"0px",
      marginBottom: 20,
      display: "block",
      left: 0,
      },
      '@media (max-width: 400px)': {
      width: "90%"
      },
  },
  boxInputOk:{
    border: "2px solid #F5A623",
  },
  boxInputError:{
    border:"2px #cc0000 solid",
  },

  contacts_form_input:{
    fontSize: 19,
    fontWeight: 300,
    borderTop: "none",
    borderBottom: "none",
    borderRight: "none",
    borderLeft: "3px solid #F5A623",
    padding: "7px 0px 7px 20px",
    width: "70%",
    outline: "none",
    '@media (max-width: 900px)': {
      width: "90%"
      },
      '@media (max-width: 500px)': {
      fontSize: 18
      },    
  },


  contacts_form_text:{
    fontSize: 25,
    fontWeight: 300,
    letterSpacing: 1,
    color: "#162A75",
    position: "relative",
    width:"calc(100% - 70px)",
    left: 110,
    margin: "30px 0px 30px",
    '@media (max-width: 900px)': {
      textAlign: "center",
      left: 0,
      margin: "20px auto",

      fontSize: 22,

    },
    '@media (max-width: 410px)': {
      fontSize: 20,
      width:"calc(100%)",
    },
    '@media (max-width: 335px)': {
      fontSize: 17,
      margin: "30px 0px 20px"
    },
  },

  contacts_form_buttonWrapper:{
    '@media (max-width: 900px)': {
      margin: "0px auto"
    },
  },
  contacts_form_buttonWrapper_margin:{
    '@media (max-width: 900px)': {
      textAlign: "center"
      },
      '@media (max-width: 450px)': {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-around",
      },
  },
  contacts_form_button_margin:{
    marginRight: 20,
    '@media (max-width: 450px)': {
      marginRight: 0,
      },
  },
  contacts_form_button:{
    backgroundColor: "#F89C2E",
    border: "none",
    padding: "10px 65px",
    color: "#fff",
    borderRadius: 4,
    fontSize: 15.84,
    letterSpacing: 2,
    fontWeight: 300,
    position: "relative",
    left: 110,
    transition: "all .2s",
    cursor:"pointer",
    '@media (max-width: 900px)': {
      left: 0,
      },
      '@media (max-width: 450px)': {
      padding: "10px 30px",
      display: "inline-block",
      width: "40%",

      },
      '@media (max-width: 350px)': {
      padding: "7px 20px",
      fontSize: 14,
      },
      ":hover":{
      boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0px 3px 6px rgba(0,0,0,0.23)",
      transform: "translateY(-2px) scaleX(1.02) scaleY(1.02)",
    },

    ":active":{
      outline: "none",
      transform: "translateY(-1px)",

    },
    ":focus":{
      outline: "none",
    },
  },

  checkboxOK:{
    color:"#142153",
    transition:"0.2s",
    
  },
  checkboxErr:{
    color:"#cc0000",
    transition:"0.2s",
    
  },
  redText:{
    color:"#cc0000",
    fontSize:14,
    marginTop:3,
    left:110,
    '@media (max-width: 900px)': {
      marginRight: "auto",
      marginLeft: "auto",
      marginTop:3,
      marginBottom:10,
      left: 0,
      textAlign:"center",
    },
  },
  redTextEmial:{
    color:"#cc0000",
    fontSize:14,
    marginTop:-16,
    marginBottom:10,
    left:110,
    '@media (max-width: 900px)': {
      marginRight: "auto",
      marginLeft: "auto",
      marginTop:-16,
      marginBottom:10,
      left: 0,
      textAlign:"center",
    },
  },

  
};

export default Radium(ContactForm);
