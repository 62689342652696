import React, {useEffect, useState} from 'react';
import Radium from 'radium';

const CookiesPopup = () => {

  const [display, setDisplay] = useState(true)
  const [delay, setDelay] = useState(true)


  useEffect(() => {
    if(document.cookie.replace(/(?:(?:^|.*;\s*)agree\s*=\s*([^;]*).*$)|^.*$/, "$1") === "yes"){
      setDelay(false)
    }
    setTimeout(() => setDisplay(false), 9400);
    setTimeout(() => setDelay(false), 10000);
  }, [])

  const displayState = display ? styles.displayON : styles.displayOFF;
  const displayOFFState = delay ? null : styles.displayOFFOFF;

  function hide(){
    setDisplay(false)
    setTimeout(() => setDelay(false), 600);
    document.cookie = "agree=yes";
  }

  return(
    <div style={[styles.wrapper, displayState, displayOFFState]}>
      <div>ČE UPORABLJATE TO STRAN, SE SOGLAŠATE K UPORABI PIŠKOTOV.</div>
      <a href="../assets/Politika_piskotkov.pdf" target="_blank" style={styles.button}>ŽELITE IZVEDETI VEČ</a>
      <img onClick={hide} style={styles.close} src="../assets/icons/closeWhite.png" alt="" />
    </div>
  )
}



const styles = {
  wrapper:{
    margin:"0 auto",
    position:"fixed",
    zIndex:1000,
    bottom:0,
    color:"#fff",
    display:"flex",
    textAlign:"center",
    justifyContent:"center",
    alignItems:"center",
    background:"#F89C2E",
    left:0,
    height:65,
    fontSize:16,
    paddingLeft:0,
    paddingRight:0,
    flexDirection:"row",
    width:"100%",
    transition:"0.5s",
    '@media (max-width: 780px)': {
      height:80,
      flexDirection:"column",
      paddingLeft:60,
      paddingRight:60,
      width:"calc(100%)",
    },
    '@media (max-width: 540px)': { 
      height:100,
    },
    '@media (max-width: 350px)': { 
      fontSize:14,
    },
  },
  displayON:{
    opacity:1,
  },
  displayOFF:{
    opacity:0,
  },
  displayOFFOFF:{
    display:"none",
  },
  button:{
    backgroundColor: "",
    borderBottom: "#fff 2px solid",
    padding: "2 2",
    color: "#fff",
    textDecoration:"none",
    marginLeft:30,
    marginTop:0,
    position: "relative",
    transition: "all .2s",
    cursor:"pointer",
    '@media (max-width: 780px)': {
      marginLeft:0,
      marginTop:7,
    },
  },
  close:{
    position:"fixed",
    width:20,
    bottom:22,
    right:30,
    cursor:"pointer",
    zIndex:10000,
    '@media (max-width: 780px)': {
      bottom:29,
      right:20,
    },
    '@media (max-width: 540px)': { 
      bottom:39,
    },
  },
  
};

export default Radium(CookiesPopup);
