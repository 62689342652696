import React from 'react';
import Radium from 'radium';
import ContactForm from '../components/ContactForm';

import MailForm from "../components/mailForm.js";



class Kontakti extends React.Component {
		state = {
	    name:"Tilen Babnik",
	    email:"",
	    tel:"",
	    nacin:"",
	    location:"home",
	    nextScreen: true,
		};


  handleClick(setState){
  	setState({ nextScreen: false});
  }

	render(){
		return(
			<div style={styles.wrapper}>
				<div style={styles.container}>
					<div style={styles.title}>
						Odvetniška družba Glušič
					</div>
					<div style={styles.contacts}>
						<div style={styles.contacts_info}>
							<div style={styles.contacts_info_1}>
								<div style={[styles.contacts_info_inline, styles.contacts_info_words]}>T:<a href="tel:+386 1 234 5347" style={[styles.contacts_info_inline, styles.contacts_info_numbers]}>+386 1 234 5347</a>
								</div>
								<div style={[styles.contacts_info_inline, styles.contacts_info_words]}>M:<a href="tel:+386 31 335 435" style={[styles.contacts_info_inline, styles.contacts_info_numbers]}>+386 31 335 435</a>
								</div> 
								<a href="mailto:info@glusic.si" target="_top" style={[styles.contacts_info_inline, styles.contacts_info_email]}>info@glusic.si</a>
							</div>
							<div style={styles.contacts_info_2}>
								<div style={styles.contacts_info_DavMat}>Davčna št.&#58; SI26463652</div>
								<div style={styles.contacts_info_DavMat}>Matična št.&#58; 6655688000</div>
							</div>
							<div style={styles.contacts_info_map}>
								<div style={styles.contacts_info_map_picture}>
									<img src="./assets/icons/placeholder-filled-point.svg" alt="map_icon" />
								</div>
								<div style={styles.contacts_info_map_text}>
									<div style={styles.contacts_info_map_text_1}>Pipanova pot 30,</div>
									<div style={styles.contacts_info_map_text_1}>1000 Ljubljana</div>

								</div>
							</div>
						</div>
						<div style={styles.contacts_form}>
							<ContactForm onClick={() => this.handleClick(this.setState)} location="contact" />
						</div>
					</div>
					<div style={styles.imageWrapper}>
						<div style={styles.imageWrapper_besedilo}>
							Uspeh je v dobrih odnosih in uspešni komunikaciji
						</div>
					</div>
					<MailForm />
				</div>
			</div>
		);
	}
}

const styles = {
	wrapper:{
		position:"relative",
		backgroundColor:"#fff",
		width: "100%",
		paddingTop:70,
		'@media (max-width: 350px)': {
			paddingTop: 30
	    },
	},
	container:{

		margin: "0 auto",
		positon: "relative",
		width: "100%",
	},
	title:{
		textAlign: "center",
		fontSize: 40,
		fontWeight: "200",
		color: "#162A75",
		marginBottom: 100,
		'@media (max-width: 1000px)': {
			marginBottom: 70
	    },
	    '@media (max-width: 900px)': {
			marginBottom: 40,
			fontSize: 35
	    },
	    '@media (max-width: 400px)': {
			marginBottom: 40,
			fontSize: 30
	    },
	    '@media (max-width: 360px)': {
			width: "90%",
			marginRight: "auto",
      marginLeft: "auto",
      marginTop:"0px",
			marginBottom: 40
	    },
	},
	contacts:{
		width: "calc(100% - 100px)",
		display: "flex",
		margin:"0px auto",
		maxWidth:1200,
		flexWrap: "nowrap",
		'@media (max-width: 1150px)': {
			width: "90%",
	    },
	    '@media (max-width: 900px)': {
			flexWrap: "nowrap",
			flexDirection: "column",
	    },
	},
	contacts_info:{
		width: "50%",
		'@media (max-width: 900px)': {
			width: "100%",
			textAlign:"center",
			order: 2,
			position: "relative",
	    },	
	},
	contacts_form:{
		'@media (max-width: 900px)': {
			marginBottom: 40,
			width: "100%"
	    },
	},
	contacts_info_1:{
		marginBottom: 40,
		'@media (max-width: 900px)': {
			marginBottom: 20,
	  },
	},
	contacts_info_2:{
		fontSize: 17,
		fontWeight: "300",
		color: "#162A75",
		marginBottom: 80,
		'@media (max-width: 900px)': {
			marginBottom: 30,
			fontSize: 20,
	    },
	},
	contacts_info_inline:{
		fontSize: 25,
		textDecoration:"none",
		fontWeight: "300",
	},
	contacts_info_numbers:{
		width: "90%",
		textDecoration:"none",
		display: "inline-block",
		color: "#162A75",
		marginBottom: 12,
		'@media (max-width: 1050px)': {
			width: "80%"
	    },
	    '@media (max-width: 900px)': {
			width: "auto",
			marginBottom: 1,
	    },
	    '@media (max-width: 500px)': {

	    }
	},
	contacts_info_words:{

		color: "#F5A623",
		marginRight: 5,
		'@media (max-width: 1050px)': {
			marginRight: 20
	    },
	    '@media (max-width: 900px)': {
	    	marginRight: 0
	    },
	},
	contacts_info_email:{
		color: "F5A623"
	},
	contacts_info_DavMat:{
		marginBottom: 7
	},
	contacts_info_map: {
		position: "relative",
	},
	contacts_info_map_picture:{
		display: "inline-block",
		padding: "20px 25px",
		border: "2px solid #F5A623",
		borderRadius: 4,
		'@media (max-width: 900px)': {
			padding: "15px 20px",
	  },
	},
	contacts_info_map_text:{
		position: "relative",
		top: 12,
		textAlign:"left",
		left: 10,
		fontSize: 18,
		fontWeight: 300,
		color: "#162A75",
		display: "inline-block",
		'@media (max-width: 340px)': {
			fontSize: 16
	    },
	},
	
	
	imageWrapper:{
		margin: "85px 0px 10px",
		borderTop:"7px solid #142153",
		height: "70vh",
		backgroundImage: "url(../assets/pictures/preurejena_opacity.png)",
		backgroundSize: "cover",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		'@media (max-width: 1100px)': {
			height: "60vh",
	    },
	    '@media (max-width: 1000px)': {
			height: "55vh",
	    },
	    '@media (max-width: 900px)': {
			height: "50vh",
			backgroundPosition: "top right",
			margin: "45px 0px 100px"
	    },
	    '@media (max-width: 750px)': {
			height: "43vh",
	    },
	    '@media (max-width: 600px)': {
			height: "40vh",
			backgroundPosition: "50% 0%"
	    },
	    '@media (max-width: 400px)': {
			margin: "45px 0px 50px"
	    },
	},
	imageWrapper_besedilo:{
		textAlign: "center",
		width: "40%",
		fontSize: 41,
		fontWeight: 300,
		color: "#162A75",
		'@media (max-width: 1175px)': {
			width:"50%"
	    },
	    '@media (max-width: 1000px)': {
			fontSize: 35
	    },
	    '@media (max-width: 900px)': {
			fontSize: 30
	    },
	    '@media (max-width: 750px)': {
			fontSize: 25,
	    },
	    '@media (max-width: 500px)': {
			width:"70%"
	    },
	},
	
};


export default Radium(Kontakti);